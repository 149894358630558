<template>
    <v-container fluid style="padding-bottom:80px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                    <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                    <v-layout column>
                        <div>
                        <strong>{{ snackbar.title }}</strong>
                        </div>
                        <div>{{ snackbar.text }}</div>
                    </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                    <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="pb-3" outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <div class="d-inline-flex ml-3">
                                    <v-checkbox
                                        v-model="selected"
                                        label="SC"
                                        value="SC"
                                        class="mr-3 ma-0"
                                        @click="checkSelected()"
                                    ></v-checkbox>
                                    <v-checkbox
                                        v-model="selected"
                                        label="PPP"
                                        value="PPP"
                                        class="mr-3 ma-0"
                                        @click="checkSelected()"
                                    ></v-checkbox>
                                    <v-checkbox
                                        v-model="selected"
                                        label="LPM"
                                        value="LPM"
                                        class="mr-3 ma-0"
                                        @click="checkSelected()"
                                    ></v-checkbox>
                                    <v-checkbox
                                        v-model="selected"
                                        label="Dispatch"
                                        value="Dispatch"
                                        class="mr-3 ma-0"
                                        @click="checkSelected()"
                                    ></v-checkbox>                                    
                                </div>                                
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Customer Category </span>
                                <v-autocomplete
                                    prepend-inner-icon="mdi-account-box"
                                    outlined
                                    dense
                                    v-model="customer"
                                    :items="customers"
                                    item-value="value"
                                    item-text="label"
                                    class="ma-0 pa-0 mt-2"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Start Periode </span>
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        outlined
                                        dense
                                        v-model="date_from"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 mt-2"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_from"
                                        type="month"
                                        no-title
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2" >
                                <span class="blue-lcd mb-2 font-12">End Periode </span>
                                <v-menu
                                    ref="modal_to"
                                    v-model="modal_to"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        outlined
                                        dense
                                        v-model="date_to"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 mt-2"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_to"
                                        type="month"
                                        no-title
                                        @input="modal_to = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Brand </span>
                                <v-autocomplete
                                    prepend-inner-icon="mdi-watermark"
                                    clearable
                                    outlined
                                    dense
                                    v-model="brand"
                                    :items="brands"
                                    item-value="brand_id"
                                    item-text="descr"
                                    class="ma-0 pa-0 mt-2"
                                    hide-details=true
                                    @change="(event) => getColour()"
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Thickness</span>
                                <v-autocomplete
                                    prepend-inner-icon="mdi-arrow-up-down"
                                    clearable
                                    outlined
                                    dense
                                    v-model="thick"
                                    :items="thickness"
                                    item-value="thick"
                                    :item-text="item => $store.getters.convertToCurrency(item.thick)"
                                    class="ma-0 pa-0 mt-2"
                                    hide-details=true
                                ></v-autocomplete>                                
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Width</span>
                                <v-autocomplete
                                    prepend-inner-icon="mdi-arrow-left-right"
                                    clearable
                                    outlined
                                    dense
                                    v-model="width"
                                    :items="widths"
                                    item-value="width"
                                    :item-text="item => $store.getters.convertToCurrency(item.width)"
                                    class="ma-0 pa-0 mt-2"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Quality</span>
                                <v-autocomplete
                                    prepend-inner-icon="mdi-quality-high"
                                    clearable
                                    outlined
                                    dense
                                    v-model="quality"
                                    :items="qualities"
                                    item-value="quality_id"
                                    item-text="quality_id"
                                    class="ma-0 pa-0 mt-2"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-btn color="info" elevation="2" class="mt-5" @click="submit()"><v-icon small class="mr-3">mdi-magnify</v-icon> Search</v-btn>
                            </v-col>
                        </v-row>                        
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-5">
            <v-col cols="12">
                <v-card id="sumScShipmentAnalysis" outlined height="420">
                    <v-card-text>
                        <h6 class="indigo-text m-0">Query Result: </h6>
                        <v-img
                        class="text-center mt-5 mx-auto"
                        lazy-src="@/assets/empty_data.png"
                        max-width="300"
                        src="@/assets/empty_data.png"
                        v-if="showData"
                        ></v-img>
                        <div id="container1" style="height: 370px; width: 100%;"></div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-5">
            <v-col class="col-12">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    :headers="header"
                                    :items="data"
                                    :loading="$store.state.overlay"
                                    :item-class="tr_datatable"
                                    hide-default-footer
                                    fixed-header
                                    height="220"
                                    :divider="true"
                                    :light="true"
                                    disable-pagination
                                >        
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-5">
            <v-col cols="12" xs="12" 
            sm="6" 
            md="4">
                <v-card outlined height="400">
                    <v-card-text>
                        <v-img
                        class="text-center mx-auto"
                        style="margin-top: 80px;"
                        lazy-src="@/assets/empty_data.png"
                        max-width="200"
                        src="@/assets/empty_data.png"
                        v-if="showDataDetail"
                        ></v-img>
                        <div id="container2" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" 
            sm="6" 
            md="4">
                <v-card outlined height="400">
                    <v-card-text>
                        <v-img
                        class="text-center mx-auto"
                        style="margin-top: 80px;"
                        lazy-src="@/assets/empty_data.png"
                        max-width="200"
                        src="@/assets/empty_data.png"
                        v-if="showDataDetail"
                        ></v-img>
                        <div id="container3" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" 
            sm="6" 
            md="4">
                <v-card outlined height="400">
                    <v-card-text>
                        <v-img
                        class="text-center mx-auto"
                        style="margin-top: 80px;"
                        lazy-src="@/assets/empty_data.png"
                        max-width="200"
                        src="@/assets/empty_data.png"
                        v-if="showDataDetail"
                        ></v-img>
                        <div id="container4" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" 
            sm="6" 
            md="4">
                <v-card outlined height="400">
                    <v-card-text>
                        <v-img
                        class="text-center mx-auto"
                        style="margin-top: 80px;"
                        lazy-src="@/assets/empty_data.png"
                        max-width="200"
                        src="@/assets/empty_data.png"
                        v-if="showDataDetail"
                        ></v-img>
                        <div id="container5" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" 
            sm="6" 
            md="4">
                <v-card outlined height="400">
                    <v-card-text>
                        <v-img
                        class="text-center mx-auto"
                        style="margin-top: 80px;"
                        lazy-src="@/assets/empty_data.png"
                        max-width="200"
                        src="@/assets/empty_data.png"
                        v-if="showDataDetail"
                        ></v-img>
                        <div id="container6" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
            <!-- <v-col cols="12" xs="12" 
            sm="6" 
            md="4">
                <v-card outlined height="400">
                    <v-card-text>
                        <v-img
                        class="text-center mx-auto"
                        style="margin-top: 80px;"
                        lazy-src="@/assets/empty_data.png"
                        max-width="200"
                        src="@/assets/empty_data.png"
                        v-if="showDataDetail"
                        ></v-img>
                        <div id="container7" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col> -->
            <v-col cols="12" xs="12" sm="6" md="4">
                <v-card outlined height="400">
                    <v-card-text>
                        <v-img
                        class="text-center mx-auto"
                        style="margin-top: 80px;"
                        lazy-src="@/assets/empty_data.png"
                        max-width="200"
                        src="@/assets/empty_data.png"
                        v-if="showDataDetail"
                        ></v-img>
                        <div id="container8" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {backendApi} from "@/backend-api/backend-api-sr"
import _ from "lodash"

export default {
    data() {
        return {
            selected: ['SC'],
            breadcumbs: [
                {
                text: 'Sunrise Mill',
                disabled: false,
                href: '/admin/sm',
                },
                {
                text: 'Delivery',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Order to Shipment Analysis',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            loading: false,
            saless: [],
            sales: '',
            customers: [
                {
                    label: 'All',
                    value: 'All'
                },
                {
                    label: 'SM',
                    value: 'SM'
                },
                {
                    label: 'SR',
                    value: 'SR'
                }
            ],
            customer: 'All',
            search: null,
            order_id: '',
            loadingOrder: false,
            items:[],
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: false, 
            brands: [],
            brand: '',
            coat_masss: [],
            coat_mass: '',
            grades: [],
            grade: '',
            thickness: [],
            thick: '',
            widths: [],
            width: '',
            colours: [],
            colour: '',
            qualities: [],
            quality: '',     
            showData: true,
            showDataDetail: true,
            dataSc:[],
            dataPpp:[],
            dataLpm:[],
            dataDispatch:[],
            header:[],
            data:[],
        }
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        // this.customer = 'All'
        await this.getBrand()
        await this.getThick()
        await this.getWidth()
        await this.getQuality()
        this.$store.dispatch('setOverlay', false)
    },
    methods:{
        tr_datatable(item) {
            const rowClass = 'tr_datatable'
            return rowClass;
        },
        close(){
            this.dialog = false
        },
        async getBrand(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sm/brand`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.brands = res.data
            });
        },
        async getThick(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sm/thicks`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.thickness = res.data
            });
        },
        async getWidth(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sm/width`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.widths = res.data
            });
        },
        async getQuality(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sm/quality-two`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.qualities = res.data
            });
        },
        checkSelected(){
            // if (this.selected.length > 2) {
                // this.selected = []
                // this.snackbar = {
                //     color: "warning",
                //     icon: "mdi-alert-circle",
                //     mode: "multi-line",
                //     position: "top",
                //     timeout: 7500,
                //     title: "Warning",
                //     text: "Please max check 2 item",
                //     visible: true
                // };
            // }
        },
        async submit(){
            this.$store.dispatch('setOverlay', true)

            if (this.date_from == '') {
                this.$store.dispatch('setOverlay', false)

                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: "Please check 2 item or fill start Period",
                    visible: true
                };
                return false
            }

            var start = this.date_from ? new Date(this.date_from).toISOString().substr(0, 7).replace(/-/g,"") : ''
            var end = this.date_to ? new Date(this.date_to).toISOString().substr(0, 7).replace(/-/g,"") : ''

            var reqBody = {
                'customer': this.customer ? this.customer : '',
                'start': start,
                'end': end,
                'brand': this.brand,
                'coat': this.coat_mass,
                'grade': this.grade,
                'thick': this.thick ? this.thick : '',
                'width': this.width ? this.width : '',
                'colour': this.colour ? this.colour : '',
                'quality': this.quality ? this.quality : ''
            }

            var dt1 = [];
            var dt2 = [];
            var dt3 = [];
            var dt4 = [];

            var data1 = {}
            var data2 = {}
            var data3 = {} 
            var data4 = {}
            this.dataSc = []
            this.dataPpp = []
            this.dataLpm = []
            this.dataDispatch = []

            var label = ''
            var container = 'container1';
            var title = " Summary";

            switch (this.selected[0]) {
                case 'SC':
                        const respData = await backendApi.fetchCore('/v2/sm/analysis/sc-prod/indexAll', reqBody, false, false, false)
                        this.dataSc = respData
                        if (respData.status === 200) {
                            label = 'SC & ';

                            for (var i = 0; i < respData.data.length; i++) {
                                dt1.push({ label: respData.data[i].periode , y: parseFloat(respData.data[i].total_wgt)});
                            }

                            data1 = {
                                type: "column",
                                click: this.onClick1,
                                name: "Total SC",
                                indexLabel: "{y}",
                                indexLabelFontSize: 14,
                                // indexLabelFontColor: "#FFF",
                                showInLegend: true,
                                indexLabelPlacement: "inside",  
                                indexLabelOrientation: "vertical",
                                // yValueFormatString: "#,###,,,.##",
                            }

                            
                        }
                    break;
                case 'PPP':
                        const respDataPPP = await backendApi.fetchCore('/v2/sm/analysis/sc-prod/indexPPP', reqBody, false, false, false)
                        this.dataPpp = respDataPPP
                        if (respDataPPP.status === 200) {
                            label = 'PPP & ';

                            for (var i = 0; i < respDataPPP.data.length; i++) {
                                dt1.push({ label: respDataPPP.data[i].periode , y: parseFloat(respDataPPP.data[i].total_wgt)});
                            }

                            data1 = {
                                type: "column",
                                click: this.onClickPPP,
                                name: "Total PPP",
                                indexLabel: "{y}",
                                indexLabelFontSize: 14,
                                // indexLabelFontColor: "#FFF",
                                showInLegend: true,
                                indexLabelPlacement: "inside",  
                                indexLabelOrientation: "vertical",
                                // yValueFormatString: "#,###,,,.##",
                            }

                            
                        }
                    break;

                case 'LPM':
                        const respDataLPM = await backendApi.fetchCore('/v2/sm/analysis/sc-prod/indexLPM', reqBody, false, false, false)
                        this.dataLpm = respDataLPM
                        if (respDataLPM.status === 200) {
                            label = 'LPM & ';

                            for (var i = 0; i < respDataLPM.data.length; i++) {
                                dt1.push({ label: respDataLPM.data[i].periode , y: parseFloat(respDataLPM.data[i].total_wgt)});
                            }

                            data1 = {
                                type: "column",
                                click: this.onClickLPM,
                                name: "Total LPM",
                                indexLabel: "{y}",
                                indexLabelFontSize: 14,
                                // indexLabelFontColor: "#FFF",
                                showInLegend: true,
                                indexLabelPlacement: "inside",  
                                indexLabelOrientation: "vertical",
                                // yValueFormatString: "#,###,,,.##",
                            }

                            
                        }
                    break;
                case 'Dispatch':
                        const respDataDispatch = await backendApi.fetchCore('/v2/sm/analysis/sc-prod/indexDispatch', reqBody, false, false, false)
                        this.dataDispatch = respDataDispatch
                        if (respDataDispatch.status === 200) {
                            label = 'Dispatch & ';

                            for (var i = 0; i < respDataDispatch.data.length; i++) {
                                dt1.push({ label: respDataDispatch.data[i].periode , y: parseFloat(respDataDispatch.data[i].total_wgt)});
                            }

                            data1 = {
                                type: "column",
                                click: this.onClick2,
                                name: "Total Dispatch",
                                indexLabel: "{y}",
                                indexLabelFontSize: 14,
                                // indexLabelFontColor: "#FFF",
                                showInLegend: true,
                                indexLabelPlacement: "inside",  
                                indexLabelOrientation: "vertical",
                                // yValueFormatString: "#,###,,,.##",
                            }
                        }
                    break; 
                default:
                    label = '';
                    dt1 = [];
                    break;
            }

            switch (this.selected[1]) {
                case 'SC':
                        const respData = await backendApi.fetchCore('/v2/sm/analysis/sc-prod', reqBody, false, false, false)
                        this.dataSc = respData
                        if (respData.status === 200) {
                            label = label + 'SC & ';

                            for (var i = 0; i < respData.data.length; i++) {
                                dt2.push({ label: respData.data[i].periode , y: parseFloat(respData.data[i].total_wgt)});
                            }

                            data2 = {
                                type: "column",
                                click: this.onClick1,
                                name: "Total SC",
                                indexLabel: "{y}",
                                indexLabelFontSize: 14,
                                // indexLabelFontColor: "#FFF",
                                showInLegend: true,
                                indexLabelPlacement: "inside",  
                                indexLabelOrientation: "vertical",
                                // yValueFormatString: "#,###,,,.##",
                            }
                        }
                    break;
                case 'PPP':
                        const respDataPPP = await backendApi.fetchCore('/v2/sm/analysis/sc-prod/indexPPP', reqBody, false, false, false)
                        this.dataPpp = respDataPPP
                        if (respDataPPP.status === 200) {
                            label = label + 'PPP & ';

                            for (var i = 0; i < respDataPPP.data.length; i++) {
                                dt2.push({ label: respDataPPP.data[i].periode , y: parseFloat(respDataPPP.data[i].total_wgt)});
                            }

                            data2 = {
                                type: "column",
                                click: this.onClickPPP,
                                name: "Total PPP",
                                indexLabel: "{y}",
                                indexLabelFontSize: 14,
                                // indexLabelFontColor: "#FFF",
                                showInLegend: true,
                                indexLabelPlacement: "inside",  
                                indexLabelOrientation: "vertical",
                                // yValueFormatString: "#,###,,,.##",
                            }

                            
                        }
                    break;
                case 'LPM':
                        const respDataLPM = await backendApi.fetchCore('/v2/sm/analysis/sc-prod/indexLPM', reqBody, false, false, false)
                        this.dataLpm = respDataLPM
                        if (respDataLPM.status === 200) {
                            label = label + 'LPM & ';

                            for (var i = 0; i < respDataLPM.data.length; i++) {
                                dt2.push({ label: respDataLPM.data[i].periode , y: parseFloat(respDataLPM.data[i].total_wgt)});
                            }

                            data2 = {
                                type: "column",
                                click: this.onClickLPM,
                                name: "Total LPM",
                                indexLabel: "{y}",
                                indexLabelFontSize: 14,
                                // indexLabelFontColor: "#FFF",
                                showInLegend: true,
                                indexLabelPlacement: "inside",  
                                indexLabelOrientation: "vertical",
                                // yValueFormatString: "#,###,,,.##",
                            }

                            
                        }
                    break;
                case 'Dispatch':
                        const respDataDispatch = await backendApi.fetchCore('/v2/sm/analysis/sc-prod/indexDispatch', reqBody, false, false, false)
                        this.dataDispatch = respDataDispatch
                        if (respDataDispatch.status === 200) {
                            label = label + 'Dispatch & ';

                            for (var i = 0; i < respDataDispatch.data.length; i++) {
                                dt2.push({ label: respDataDispatch.data[i].periode , y: parseFloat(respDataDispatch.data[i].total_wgt)});
                            }

                            data2 = {
                                type: "column",
                                click: this.onClick2,
                                name: "Total Dispatch",
                                indexLabel: "{y}",
                                indexLabelFontSize: 14,
                                // indexLabelFontColor: "#FFF",
                                showInLegend: true,
                                indexLabelPlacement: "inside",  
                                indexLabelOrientation: "vertical",
                                // yValueFormatString: "#,###,,,.##",
                            }
                        }
                    break; 
                default:
                    label = '';
                    dt2 = [];
                    break;
            }

            switch (this.selected[2]) {
                case 'SC':
                        const respData = await backendApi.fetchCore('/v2/sm/analysis/sc-prod', reqBody, false, false, false)
                        this.dataSc = respData
                        if (respData.status === 200) {
                            label = label + 'SC &';

                            for (var i = 0; i < respData.data.length; i++) {
                                dt3.push({ label: respData.data[i].periode , y: parseFloat(respData.data[i].total_wgt)});
                            }

                            data3 = {
                                type: "column",
                                click: this.onClick1,
                                name: "Total SC",
                                indexLabel: "{y}",
                                indexLabelFontSize: 14,
                                // indexLabelFontColor: "#FFF",
                                showInLegend: true,
                                indexLabelPlacement: "inside",  
                                indexLabelOrientation: "vertical",
                                // yValueFormatString: "#,###,,,.##",
                            }
                        }
                    break;
                case 'PPP':
                        const respDataPPP = await backendApi.fetchCore('/v2/sm/analysis/sc-prod/indexPPP', reqBody, false, false, false)
                        this.dataPpp = respDataPPP
                        if (respDataPPP.status === 200) {
                            label = label + 'PPP &';

                            for (var i = 0; i < respDataPPP.data.length; i++) {
                                dt3.push({ label: respDataPPP.data[i].periode , y: parseFloat(respDataPPP.data[i].total_wgt)});
                            }

                            data3 = {
                                type: "column",
                                click: this.onClickPPP,
                                name: "Total PPP",
                                indexLabel: "{y}",
                                indexLabelFontSize: 14,
                                // indexLabelFontColor: "#FFF",
                                showInLegend: true,
                                indexLabelPlacement: "inside",  
                                indexLabelOrientation: "vertical",
                                // yValueFormatString: "#,###,,,.##",
                            }

                            
                        }
                    break;
                case 'LPM':
                        const respDataLPM = await backendApi.fetchCore('/v2/sm/analysis/sc-prod/indexLPM', reqBody, false, false, false)
                        this.dataLpm = respDataLPM
                        if (respDataLPM.status === 200) {
                            label = label + 'LPM &';

                            for (var i = 0; i < respDataLPM.data.length; i++) {
                                dt3.push({ label: respDataLPM.data[i].periode , y: parseFloat(respDataLPM.data[i].total_wgt)});
                            }

                            data3 = {
                                type: "column",
                                click: this.onClickLPM,
                                name: "Total LPM",
                                indexLabel: "{y}",
                                indexLabelFontSize: 14,
                                // indexLabelFontColor: "#FFF",
                                showInLegend: true,
                                indexLabelPlacement: "inside",  
                                indexLabelOrientation: "vertical",
                                // yValueFormatString: "#,###,,,.##",
                            }

                            
                        }
                    break;
                case 'Dispatch':
                        const respDataDispatch = await backendApi.fetchCore('/v2/sm/analysis/sc-prod/indexDispatch', reqBody, false, false, false)
                        this.dataDispatch = respDataDispatch
                        if (respDataDispatch.status === 200) {
                            label = label + 'Dispatch &';

                            for (var i = 0; i < respDataDispatch.data.length; i++) {
                                dt3.push({ label: respDataDispatch.data[i].periode , y: parseFloat(respDataDispatch.data[i].total_wgt)});
                            }

                            data3 = {
                                type: "column",
                                click: this.onClick2,
                                name: "Total Dispatch",
                                indexLabel: "{y}",
                                indexLabelFontSize: 14,
                                // indexLabelFontColor: "#FFF",
                                showInLegend: true,
                                indexLabelPlacement: "inside",  
                                indexLabelOrientation: "vertical",
                                // yValueFormatString: "#,###,,,.##",
                            }
                        }
                    break; 
                default:
                    label = '';
                    dt3 = [];
                    break;
            }

            switch (this.selected[3]) {
                case 'SC':
                        const respData = await backendApi.fetchCore('/v2/sm/analysis/sc-prod', reqBody, false, false, false)
                        this.dataSc = respData
                        if (respData.status === 200) {
                            label = label + ' SC';

                            for (var i = 0; i < respData.data.length; i++) {
                                dt4.push({ label: respData.data[i].periode , y: parseFloat(respData.data[i].total_wgt)});
                            }

                            data4 = {
                                type: "column",
                                click: this.onClick1,
                                name: "Total SC",
                                indexLabel: "{y}",
                                indexLabelFontSize: 14,
                                // indexLabelFontColor: "#FFF",
                                showInLegend: true,
                                indexLabelPlacement: "inside",  
                                indexLabelOrientation: "vertical",
                                // yValueFormatString: "#,###,,,.##",
                            }
                        }
                    break;
                case 'PPP':
                        const respDataPPP = await backendApi.fetchCore('/v2/sm/analysis/sc-prod/indexPPP', reqBody, false, false, false)
                        this.dataPpp = respDataPPP
                        if (respDataPPP.status === 200) {
                            label = label + ' PPP';

                            for (var i = 0; i < respDataPPP.data.length; i++) {
                                dt4.push({ label: respDataPPP.data[i].periode , y: parseFloat(respDataPPP.data[i].total_wgt)});
                            }

                            data4 = {
                                type: "column",
                                click: this.onClickPPP,
                                name: "Total PPP",
                                indexLabel: "{y}",
                                indexLabelFontSize: 14,
                                // indexLabelFontColor: "#FFF",
                                showInLegend: true,
                                indexLabelPlacement: "inside",  
                                indexLabelOrientation: "vertical",
                                // yValueFormatString: "#,###,,,.##",
                            }

                            
                        }
                    break;
                case 'LPM':
                        const respDataLPM = await backendApi.fetchCore('/v2/sm/analysis/sc-prod/indexLPM', reqBody, false, false, false)
                        this.dataLpm = respDataLPM
                        if (respDataLPM.status === 200) {
                            label = label + ' LPM';

                            for (var i = 0; i < respDataLPM.data.length; i++) {
                                dt4.push({ label: respDataLPM.data[i].periode , y: parseFloat(respDataLPM.data[i].total_wgt)});
                            }

                            data4 = {
                                type: "column",
                                click: this.onClickLPM,
                                name: "Total LPM",
                                indexLabel: "{y}",
                                indexLabelFontSize: 14,
                                // indexLabelFontColor: "#FFF",
                                showInLegend: true,
                                indexLabelPlacement: "inside",  
                                indexLabelOrientation: "vertical",
                                // yValueFormatString: "#,###,,,.##",
                            }

                            
                        }
                    break;
                case 'Dispatch':
                        const respDataDispatch = await backendApi.fetchCore('/v2/sm/analysis/sc-prod/indexDispatch', reqBody, false, false, false)
                        this.dataDispatch = respDataDispatch
                        if (respDataDispatch.status === 200) {
                            label = label + ' Dispatch';

                            for (var i = 0; i < respDataDispatch.data.length; i++) {
                                dt4.push({ label: respDataDispatch.data[i].periode , y: parseFloat(respDataDispatch.data[i].total_wgt)});
                            }

                            data4 = {
                                type: "column",
                                click: this.onClick2,
                                name: "Total Dispatch",
                                indexLabel: "{y}",
                                indexLabelFontSize: 14,
                                // indexLabelFontColor: "#FFF",
                                showInLegend: true,
                                indexLabelPlacement: "inside",  
                                indexLabelOrientation: "vertical",
                                // yValueFormatString: "#,###,,,.##",
                            }
                        }
                    break; 
                default:
                    label = '';
                    dt4 = [];
                    break;
            }
            console.log('data 1', dt1)
            console.log('data 2', dt2)
            console.log('data 3', dt3)
            console.log('data 4', dt4)
            dt1 = this.fillMissingLabelsByYear(dt1, start, end)
            dt2 = this.fillMissingLabelsByYear(dt2, start, end)
            dt3 = this.fillMissingLabelsByYear(dt3, start, end)
            dt4 = this.fillMissingLabelsByYear(dt4, start, end)
            console.log('data 1', dt1)
            console.log('data 2', dt2)
            console.log('data 3', dt3)
            console.log('data 4', dt4)

            this.getChart(dt1, dt2, dt3, dt4, container, title, label, data1, data2, data3, data4);
            this.createTable()

            $('html, body').animate({
                scrollTop: $("#sumScShipmentAnalysis").offset().top
            }, 1200)

            this.showData = false

            this.$store.dispatch('setOverlay', false)

        },

        generateLabels(startLabel, endLabel) {
            const labels = [];
            let [startYear, startMonth] = [parseInt(startLabel.substring(0, 4), 10), parseInt(startLabel.substring(4, 6), 10)];
            const [endYear, endMonth] = [parseInt(endLabel.substring(0, 4), 10), parseInt(endLabel.substring(4, 6), 10)];

            while (startYear < endYear || (startYear === endYear && startMonth <= endMonth)) {
                const label = `${startYear}${startMonth.toString().padStart(2, '0')}`;
                labels.push(label);

                startMonth++;
                if (startMonth > 12) {
                    startMonth = 1;
                    startYear++;
                }
            }

            return labels;
        },
        fillMissingLabelsByYear(data, startLabel, endLabel) {
            // _.forEach(data, (dataset, key) => {
                // Extract year from the dataset key
                // const datasetYear = parseInt(key.split(" ")[1], 10);

                // Generate labels for the past 12 months
                const labels = this.generateLabels(startLabel, endLabel);
                
                // Get existing labels in the dataset
                const existingLabels = _.map(data, 'label');
                
                // Determine which labels are missing
                const missingLabels = _.difference(labels, existingLabels);

                // Add missing labels to the dataset with a value of null
                _.forEach(missingLabels, (label) => {
                    data.push({ label: label, y: 0 });
                });

                // Sort dataset by label to maintain chronological order
                data.sort((a, b) => a.label.localeCompare(b.label));
            // });

            return data;
        },

        createTable(){
            this.header=[]
            this.data=[]
            this.selected.forEach((item, index) => {
                switch (item){
                    case 'SC':
                        if(this.dataSc.status == 200){
                            this.data.push(this.mapLevelOne(this.dataSc.data, item))
                        }
                        break;
                    case 'PPP':
                        if(this.dataPpp.status == 200){
                            this.data.push(this.mapLevelOne(this.dataPpp.data, item))
                        }
                        break;
                    case 'LPM':
                        if(this.dataLpm.status == 200){
                            this.data.push(this.mapLevelOne(this.dataLpm.data, item))
                        }
                        break;
                    case 'Dispatch':
                        if(this.dataDispatch.status == 200){
                            this.data.push(this.mapLevelOne(this.dataDispatch.data, item))
                        }
                        break;
                }
                
            })
            this.header = this.mapLevelTwo(this.data, 'Type')
        },

        mapLevelOne(data, item){
            let minPeriod = Number.MAX_SAFE_INTEGER;
            let maxPeriod = Number.MIN_SAFE_INTEGER;

            data.forEach(item => {
                const period = parseInt(item.periode);
                minPeriod = Math.min(minPeriod, period);
                maxPeriod = Math.max(maxPeriod, period);
            });

            const transformedObject = data.reduce((acc, curr) => {
                const period = parseInt(curr.periode);
                if (period >= minPeriod && period <= maxPeriod) {
                    acc[curr.periode] = this.$store.getters.convertToCurrencyUs(parseFloat(curr.total_wgt))
                }
                return acc;
            }, {});
            transformedObject.type = item;
            return transformedObject;
        },

        mapLevelTwo(data, customDesc){
            const header = [
                { text: customDesc, align: 'start', value: 'type', align: 'left' }
            ];

            const keys = Object.keys(data[0]).filter(key => key !== 'type');

            keys.forEach(key => {
                header.push({ text: key, value: key, align: 'right' });
            });
            return header;
        },

        getChart(dt1, dt2, dt3, dt4, container, title, label, data1, data2, data3, data4){
            var chart = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                exportEnabled: true,
                title: {
                    text: title,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: true,
                subtitles:[
                    {
                        text: label,
                        //Uncomment properties below to see how they behave
                        fontFamily: "Calibri",
                        fontColor: "red",
                        fontSize: 12
                    }
                ],
                axisY: {
                    // scaleBreaks: {
                    //     autoCalculate: true,
                    //     type: "wavy"
                    // },
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: "TON",
                    labelFormatter: this.addSymbols,
                },
                toolTip:{
                    shared:true
                },
                legend: {
                    // cursor: "pointer",
                    itemclick: this.toggleDataSeries,
                    fontSize: 11
                },
                data: [ data1, data2, data3, data4 ]
            });

            chart.options.data[0].dataPoints = dt1;
            chart.options.data[1].dataPoints = dt2;
            chart.options.data[2].dataPoints = dt3;
            chart.options.data[3].dataPoints = dt4;

            this.showDefaultText(chart, "No Data Found!");
            var x = window.matchMedia("(max-width: 991px)");
            if (x.matches) {

                for(var i = 0; i < chart.options.data.length; i++){
                    chart.options.data[i].indexLabelFontSize = 8;
                }
                chart.render();
            }
            chart.render();
        },
        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
        },
        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },
        toggleDataPointVisibility(e) {
            if(e.dataPoint.hasOwnProperty("actualYValue") && e.dataPoint.actualYValue !== null) {
                e.dataPoint.y = e.dataPoint.actualYValue;
                e.dataPoint.actualYValue = null;
                e.dataPoint.indexLabelFontSize = null;
                e.dataPoint.indexLabelLineThickness = null;
                e.dataPoint.legendMarkerType = "circle";
            } 
            else {
                e.dataPoint.actualYValue = e.dataPoint.y;
                e.dataPoint.y = 0;
                e.dataPoint.indexLabelFontSize = 0;
                e.dataPoint.indexLabelLineThickness = 0; 
                e.dataPoint.legendMarkerType = "cross";
            }
                e.chart.render();
        },
        showDefaultText(chart, text) {
            var dataPoints = chart.options.data[0].dataPoints;
            var isEmpty = !(dataPoints && dataPoints.length > 0);

            if (!isEmpty) {
                for (var i = 0; i < dataPoints.length; i++) {
                isEmpty = !dataPoints[i].y;
                if (!isEmpty)
                    break;
                }
            }

            if (!chart.options.subtitles)
                chart.options.subtitles = [];
            if (isEmpty) {
                chart.options.subtitles.push({
                text: text,
                verticalAlign: 'center',
                });
                chart.options.data[0].showInLegend = false;
            } else {
                chart.options.data[0].showInLegend = true;
            }
        },
        async onClick1(e){
            this.$store.dispatch('setOverlay', true)

            var reqBody = {
                'periode': e.dataPoint.label,
                'tahun': e.dataPoint.label.substr(0, 4),
                'bulan': e.dataPoint.label.substr(4, 6),
                'customer': this.customer ? this.customer : '',
                'brand': this.brand,
                'coat': this.coat_mass,
                'grade': this.grade,
                'thick': this.thick ? this.thick : '',
                'width': this.width ? this.width : '',
                'colour': this.colour ? this.colour : '',
                'quality': this.quality ? this.quality : ''
            }

            const respData = await backendApi.fetchCore('/v2/sm/analysis/sc-prod/getScShipmentAnalysisDetailbySC', reqBody, false, false, false)

            if (respData.status === 200) {
                console.log(respData.data);
                var param = e.dataPoint.label;
                var label_detail = "Periode: "+param;
                this.showDataDetail = false

                for (var i = 0; i < respData.data.qWidth.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qWidth.length; i++) {

                        dt_detail.push({  label: respData.data.qWidth[i].width,  y: parseFloat(respData.data.qWidth[i].total_wgt), legendText: respData.data.qWidth[i].width });

                    }
                    var container_detail = 'container2';
                    var title_detail = 'by Width SC'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respData.data.qThick.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qThick.length; i++) {

                        dt_detail.push({  label: respData.data.qThick[i].thick,  y: parseFloat(respData.data.qThick[i].total_wgt), legendText: respData.data.qThick[i].thick });

                    }
                    var container_detail = 'container3';
                    var title_detail = 'by Thickness SC'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respData.data.qBrand.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qBrand.length; i++) {

                        dt_detail.push({  label: respData.data.qBrand[i].brand_name,  y: parseFloat(respData.data.qBrand[i].total_wgt), legendText: respData.data.qBrand[i].brand_name });

                    }
                    var container_detail = 'container4';
                    var title_detail = 'by Brand Name SC'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respData.data.byOrder.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.byOrder.length; i++) {

                        dt_detail.push({  label: respData.data.byOrder[i].order_type,  y: parseFloat(respData.data.byOrder[i].total_wgt), legendText: respData.data.byOrder[i].order_type });

                    }
                    var container_detail = 'container5';
                    var title_detail = 'by Order Type SC'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respData.data.qQuality.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qQuality.length; i++) {

                        dt_detail.push({  label: respData.data.qQuality[i].quality_id,  y: parseFloat(respData.data.qQuality[i].total_wgt), legendText: respData.data.qQuality[i].quality_id });

                    }
                    var container_detail = 'container6';
                    var title_detail = 'by Quality SC'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                // for (var i = 0; i < respData.data.qColor.length; i++) {

                //     var dt_detail =  [];

                //     for (var i = 0; i < respData.data.qColor.length; i++) {

                //         dt_detail.push({  label: respData.data.qColor[i].color_name,  y: parseFloat(respData.data.qColor[i].total_wgt), legendText: respData.data.qColor[i].color_name });

                //     }
                //     var container_detail = 'container7';
                //     var title_detail = 'by Color SC'
                //     this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                // }

                for (var i = 0; i < respData.data.byCust.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.byCust.length; i++) {

                        dt_detail.push({  label: respData.data.byCust[i].custKKA,  y: parseFloat(respData.data.byCust[i].total_wgt), legendText: respData.data.byCust[i].custKKA });

                    }
                    var container_detail = 'container8';
                    var title_detail = 'by Customer Category'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }
                this.$store.dispatch('setOverlay', false)
            }
        },
        async onClick2(e){
            this.$store.dispatch('setOverlay', true)

            var reqBody = {
                'periode': e.dataPoint.label,
                'tahun': e.dataPoint.label.substr(0, 4),
                'bulan': e.dataPoint.label.substr(4, 6),
                'customer': this.customer ? this.customer : '',
                'brand': this.brand,
                'coat': this.coat_mass,
                'grade': this.grade,
                'thick': this.thick ? this.thick : '',
                'width': this.width ? this.width : '',
                'colour': this.colour ? this.colour : '',
                'quality': this.quality ? this.quality : ''
            }

            const respData = await backendApi.fetchCore('/v2/sm/analysis/sc-prod/getScShipmentAnalysisDetailbyDeliv', reqBody, false, false, false)

            if (respData.status === 200) {
                console.log(respData.data);
                this.showDataDetail = false
                var param = e.dataPoint.label;
                var label_detail = "Periode: "+param;

                for (var i = 0; i < respData.data.qWidth.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qWidth.length; i++) {

                        dt_detail.push({  label: respData.data.qWidth[i].width,  y: parseFloat(respData.data.qWidth[i].total_wgt), legendText: respData.data.qWidth[i].width });

                    }
                    var container_detail = 'container2';
                    var title_detail = 'by Width Dispatch'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respData.data.qThick.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qThick.length; i++) {

                        dt_detail.push({  label: respData.data.qThick[i].thick,  y: parseFloat(respData.data.qThick[i].total_wgt), legendText: respData.data.qThick[i].thick });

                    }
                    var container_detail = 'container3';
                    var title_detail = 'by Thickness Dispatch'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respData.data.qBrand.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qBrand.length; i++) {

                        dt_detail.push({  label: respData.data.qBrand[i].brand_name,  y: parseFloat(respData.data.qBrand[i].total_wgt), legendText: respData.data.qBrand[i].brand_name });

                    }
                    var container_detail = 'container4';
                    var title_detail = 'by Brand Name Dispatch'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respData.data.byOrder.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.byOrder.length; i++) {

                        dt_detail.push({  label: respData.data.byOrder[i].order_type,  y: parseFloat(respData.data.byOrder[i].total_wgt), legendText: respData.data.byOrder[i].order_type });

                    }
                    var container_detail = 'container5';
                    var title_detail = 'by Order Type Dispatch'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respData.data.qQuality.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qQuality.length; i++) {

                        dt_detail.push({  label: respData.data.qQuality[i].quality_id,  y: parseFloat(respData.data.qQuality[i].total_wgt), legendText: respData.data.qQuality[i].quality_id });

                    }
                    var container_detail = 'container6';
                    var title_detail = 'by Quality Dispatch'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                // for (var i = 0; i < respData.data.qColor.length; i++) {

                //     var dt_detail =  [];

                //     for (var i = 0; i < respData.data.qColor.length; i++) {

                //         dt_detail.push({  label: respData.data.qColor[i].color_name,  y: parseFloat(respData.data.qColor[i].total_wgt), legendText: respData.data.qColor[i].color_name });

                //     }
                //     var container_detail = 'container7';
                //     var title_detail = 'by Color Dispatch'
                //     this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                // }

                for (var i = 0; i < respData.data.byCust.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.byCust.length; i++) {

                        dt_detail.push({  label: respData.data.byCust[i].custKKA,  y: parseFloat(respData.data.byCust[i].total_wgt), legendText: respData.data.byCust[i].custKKA });

                    }
                    var container_detail = 'container8';
                    var title_detail = 'by Customer Category'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }
                this.$store.dispatch('setOverlay', false)
            }
        },
        async onClickPPP(e){
            this.$store.dispatch('setOverlay', true)

            var reqBody = {
                'periode': e.dataPoint.label,
                'tahun': e.dataPoint.label.substr(0, 4),
                'bulan': e.dataPoint.label.substr(4, 6),
                'customer': this.customer ? this.customer : '',
                'brand': this.brand,
                'coat': this.coat_mass,
                'grade': this.grade,
                'thick': this.thick ? this.thick : '',
                'width': this.width ? this.width : '',
                'colour': this.colour ? this.colour : '',
                'quality': this.quality ? this.quality : ''
            }

            const respData = await backendApi.fetchCore('/v2/sm/analysis/sc-prod/ppp/detailWidth', reqBody, false, false, false)
            const respDataThick = await backendApi.fetchCore('/v2/sm/analysis/sc-prod/ppp/detailThick', reqBody, false, false, false)
            // const respDataCoatMass = await backendApi.fetchCore('/v2/sm/analysis/sc-prod/ppp/detailCoatMass', reqBody, false, false, false)
            // const respDataGrade = await backendApi.fetchCore('/v2/sm/analysis/sc-prod/ppp/detailGrade', reqBody, false, false, false)
            const respDataQuality = await backendApi.fetchCore('/v2/sm/analysis/sc-prod/ppp/detailQuality', reqBody, false, false, false)
            const respDataBrand = await backendApi.fetchCore('/v2/sm/analysis/sc-prod/ppp/detailBrand', reqBody, false, false, false)
            // const respDataColor = await backendApi.fetchCore('/v2/sm/analysis/sc-prod/ppp/detailColor', reqBody, false, false, false)
            const respDataCustCateg = await backendApi.fetchCore('/v2/sm/analysis/sc-prod/ppp/detailCustomer', reqBody, false, false, false)
            const respDataOrderType = await backendApi.fetchCore('/v2/sm/analysis/sc-prod/ppp/detailOrderType', reqBody, false, false, false)

            // if (respData.status === 200 && respDataThick.status === 200 && respDataCoatMass.status === 200 && respDataGrade.status === 200 && respDataQuality.status === 200 && respDataColor.status === 200) {
            if (respData.status === 200 && respDataThick.status === 200 && respDataQuality.status === 200 && respDataBrand.status === 200 && respDataOrderType.status === 200) {
                this.showDataDetail = false
                var param = e.dataPoint.label;
                var label_detail = "Periode: "+param;

                for (var i = 0; i < respData.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.length; i++) {

                        dt_detail.push({  label: respData.data[i].width,  y: parseFloat(respData.data[i].total_wgt), legendText: respData.data[i].width });

                    }
                    var container_detail = 'container2';
                    var title_detail = 'by Width PPP'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respDataThick.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataThick.data.length; i++) {

                        dt_detail.push({  label: respDataThick.data[i].thick,  y: parseFloat(respDataThick.data[i].total_wgt), legendText: respDataThick.data[i].thick });

                    }
                    var container_detail = 'container3';
                    var title_detail = 'by Thickness PPP'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respDataBrand.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataBrand.data.length; i++) {

                        dt_detail.push({  label: respDataBrand.data[i].brand_name,  y: parseFloat(respDataBrand.data[i].total_wgt), legendText: respDataBrand.data[i].brand_name });

                    }
                    var container_detail = 'container4';
                    var title_detail = 'by Brand PPP'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respDataOrderType.data.length; i++) {
                    var dt_detail =  [];
                    for (var i = 0; i < respDataOrderType.data.length; i++) {
                        dt_detail.push({  label: respDataOrderType.data[i].order_type,  y: parseFloat(respDataOrderType.data[i].total_wgt), legendText: respDataOrderType.data[i].order_type });
                    }
                    var container_detail = 'container5';
                    var title_detail = 'by Order Type PPP'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)
                }

                for (var i = 0; i < respDataQuality.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataQuality.data.length; i++) {

                        dt_detail.push({  label: respDataQuality.data[i].quality_id,  y: Number(respDataQuality.data[i].total_wgt), legendText: respDataQuality.data[i].quality_id });

                    }
                    var container_detail = 'container6';
                    var title_detail = 'by Quality PPP'
                    await this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                // for (var i = 0; i < respDataColor.data.length; i++) {

                //     var dt_detail =  [];

                //     for (var i = 0; i < respDataColor.data.length; i++) {

                //         dt_detail.push({  label: respDataColor.data[i].color_name,  y: parseFloat(respDataColor.data[i].total_wgt), legendText: respDataColor.data[i].color_name });

                //     }
                //     var container_detail = 'container7';
                //     var title_detail = 'by Color PPP'
                //     this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                // }

                for (var i = 0; i < respDataCustCateg.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataCustCateg.data.length; i++) {

                        dt_detail.push({  label: respDataCustCateg.data[i].CustKKA,  y: parseFloat(respDataCustCateg.data[i].total_wgt), legendText: respDataCustCateg.data[i].CustKKA });

                    }
                    var container_detail = 'container8';
                    var title_detail = 'by Customer Category PPP'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                this.$store.dispatch('setOverlay', false)

            } else {
                this.$store.dispatch('setOverlay', false)
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Server Internal Error",
                    visible: true
                };
                return false
            }
                
        },
        async onClickLPM(e){
            this.$store.dispatch('setOverlay', true)

            var reqBody = {
                'periode': e.dataPoint.label,
                'tahun': e.dataPoint.label.substr(0, 4),
                'bulan': e.dataPoint.label.substr(4, 6),
                'customer': this.customer ? this.customer : '',
                'brand': this.brand,
                'coat': this.coat_mass,
                'grade': this.grade,
                'thick': this.thick ? this.thick : '',
                'width': this.width ? this.width : '',
                'colour': this.colour ? this.colour : '',
                'quality': this.quality ? this.quality : ''
            }

            const respData = await backendApi.fetchCore('/v2/sm/analysis/sc-prod/getScShipmentAnalysisDetailbyLPM', reqBody, false, false, false)

            if (respData.status === 200) {
                console.log(respData.data);
                this.showDataDetail = false
                var param = e.dataPoint.label;
                var label_detail = "Periode: "+param;

                for (var i = 0; i < respData.data.qWidth.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qWidth.length; i++) {

                        dt_detail.push({  label: respData.data.qWidth[i].width,  y: parseFloat(respData.data.qWidth[i].total_wgt), legendText: respData.data.qWidth[i].width });

                    }
                    var container_detail = 'container2';
                    var title_detail = 'by Width LPM'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respData.data.qThick.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qThick.length; i++) {

                        dt_detail.push({  label: respData.data.qThick[i].thick,  y: parseFloat(respData.data.qThick[i].total_wgt), legendText: respData.data.qThick[i].thick });

                    }
                    var container_detail = 'container3';
                    var title_detail = 'by Thickness LPM'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respData.data.qBrand.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qBrand.length; i++) {

                        dt_detail.push({  label: respData.data.qBrand[i].brand_name,  y: parseFloat(respData.data.qBrand[i].total_wgt), legendText: respData.data.qBrand[i].brand_name });

                    }
                    var container_detail = 'container4';
                    var title_detail = 'by Brand LPM'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respData.data.byOrder.length; i++) {
                    var dt_detail =  [];
                    for (var i = 0; i < respData.data.byOrder.length; i++) {
                        dt_detail.push({  label: respData.data.byOrder[i].order_type,  y: parseFloat(respData.data.byOrder[i].total_wgt), legendText: respData.data.byOrder[i].order_type });
                    }
                    var container_detail = 'container5';
                    var title_detail = 'by Order Type LPM'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)
                }

                for (var i = 0; i < respData.data.qQuality.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qQuality.length; i++) {

                        dt_detail.push({  label: respData.data.qQuality[i].quality_id,  y: Number(respData.data.qQuality[i].total_wgt), legendText: respData.data.qQuality[i].quality_id });

                    }
                    var container_detail = 'container6';
                    var title_detail = 'by Quality LPM'
                    await this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                // for (var i = 0; i < respData.data.qColor.length; i++) {

                //     var dt_detail =  [];

                //     for (var i = 0; i < respData.data.qColor.length; i++) {

                //         dt_detail.push({  label: respData.data.qColor[i].color_name,  y: parseFloat(respData.data.qColor[i].total_wgt), legendText: respData.data.qColor[i].color_name });

                //     }
                //     var container_detail = 'container7';
                //     var title_detail = 'by Color LPM'
                //     this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                // }

                for (var i = 0; i < respData.data.byCust.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.byCust.length; i++) {

                        dt_detail.push({  label: respData.data.byCust[i].custKKA,  y: parseFloat(respData.data.byCust[i].total_wgt), legendText: respData.data.byCust[i].custKKA });

                    }
                    var container_detail = 'container8';
                    var title_detail = 'by Customer Category LPM'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }


                this.$store.dispatch('setOverlay', false)
            }
        },
        getPieChart(dt_detail, container_detail, title_detail, label_detail){
            var pie_chart = new CanvasJS.Chart(container_detail, {
                animationEnabled: true,
                exportEnabled: true,
                theme: "light2",
                exportEnabled: true,
                title: {
                    text: title_detail,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: true,
                subtitles:[
                    {
                        text: label_detail,
                        //Uncomment properties below to see how they behave
                        fontFamily: "Calibri",
                        fontColor: "red",
                        fontSize: 12
                    }
                ],
                legend: {
                    itemclick: this.toggleDataPointVisibility
                },
                data: [{

                    type: "pie",
                    percentFormatString: "#0.##",
                    indexLabel: "{label} #percent%",
                    indexLabelFontSize: 12,

                }]
            });

            pie_chart.options.data[0].dataPoints = dt_detail;
            this.showDefaultText(pie_chart, "No Data Found!");
            var x = window.matchMedia("(max-width: 991px)");

            if (x.matches) {

                for(var i = 0; i < pie_chart.options.data.length; i++){
                    pie_chart.options.data[i].indexLabelFontSize = 6;
                }
                pie_chart.render();
            }
            pie_chart.render();
        }
    },
    watch: {
    }
}
</script>
<style scoped>
    .tr_datatable{
    background-color: #F5F7F8 !important;
    }
</style>